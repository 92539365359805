/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { User } from '@/data/User';
import { getCollectCarriersPage } from '@/data/containers/CollectCarriersPage';
import { getContactPage } from '@/data/containers/ContactPage';
import { getCreditCardsPage } from '@/data/containers/CreditCardPage';
import { getQuotesPage } from '@/data/containers/Quotes';
import { getQuoteDetailsPage } from '@/data/containers/QuotesDetailsPage';
import { RouteProtection } from '@/data/containers/manifest';
import { ContainerLayout } from '@/data/types/ContainerLayout';
import { Layout } from '@/data/types/Layout';
import { validateProtectedRoute } from '@/data/utils/validateProtectedRoute';
import { TranslationTable } from 'integration/generated/translations';
const layoutManifest = {
	ContactUsPage: getContactPage,
	CollectCarriersPage: getCollectCarriersPage,
	CreditCardsPage: getCreditCardsPage,
	QuotesPage: getQuotesPage,
	QuoteDetailsPage: getQuoteDetailsPage,
};

export const dataContainerManifestCustom: {
	[key: string]: (cLayout: ContainerLayout) => Layout;
} = layoutManifest;

type LayoutKeys = keyof typeof layoutManifest;
type LocalRoutes = TranslationTable['Routes'];

/**
 * Layout relationships with translation route name as the key and
 * the layoutManifest key as the value.
 */
export const dataRouteManifestCustom: Record<string, LayoutKeys> = {
	ContactUs: 'ContactUsPage',
	CollectCarriers: 'CollectCarriersPage',
	CreditCards: 'CreditCardsPage',
	Quotes: 'QuotesPage',
	QuoteDetails: 'QuoteDetailsPage',
};

/**
 * Map of routes that are dependent on the user status for conditional
 * redirection or other protection. The method return dictates the
 * behavior:
 * 	- true: allow access
 * 	- false: do not load the route
 * 	- string: redirect to the route named in the string
 */
export const dataRouteProtectionCustom: Partial<
	Record<keyof LocalRoutes, (user: Partial<User>) => RouteProtection>
> = {
	CollectCarriers: (user) => validateProtectedRoute({ user }, 'login'),
	CreditCards: (user) => validateProtectedRoute({ user }, 'login'),
	Quotes: (user) => validateProtectedRoute({ user }, 'login'),
	QuoteDetails: (user) => validateProtectedRoute({ user }, 'login'),
};
