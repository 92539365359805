import { Layout } from '@/data/types/Layout';

export const getContactPage = (): Layout => ({
	name: 'DoubleStack',
	slots: {
		header: [{ name: 'Header', id: 'header' }],
		first: [{ name: 'ContactUs', id: 'contactus' }],
		second: [],
		footer: [{ name: 'Footer', id: 'footer' }],
	},
});
