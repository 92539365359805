import { Layout } from '@/data/types/Layout';
export const getQuotesPage = (): Layout => ({
	name: 'Aside',
	slots: {
		header: [{ name: 'Header', id: 'header' }],
		aside: [{ name: 'AccountSidebar', id: 'side-bar' }],
		second: [{ name: 'Quotes', id: 'quotes' }],
		footer: [{ name: 'Footer', id: 'footer' }],
	},
});
