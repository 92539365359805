/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { DefaultContainerLayout } from '@/data/types/ContainerLayout';

const Layout: DefaultContainerLayout = {
	name: 'CategoryLandingPageLayout',
	containerName: 'category-landing-page',
	isStoreDefault: true,
	slots: [
		{
			id: '1',
			widgets: [
				{
					widgetName: 'dival-category-page-widget',
					name: 'DiValCategoryPage',
					sequence: 0,
				},
			],
		},
		{
			id: '2',
			widgets: [],
		},
	],
};

export default Layout;
