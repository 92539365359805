import { DefaultContainerLayout } from '@/data/types/ContainerLayout';
const Layout: DefaultContainerLayout = {
	name: 'HomePageLayout',
	containerName: 'home-page',
	isStoreDefault: true,
	slots: [
		{
			id: '1',
			widgets: [
				{
					widgetName: 'content-carousel-widget',
					name: 'HomePageCarousel',
					sequence: 0,
					properties: {
						emsName: 'HomePageCarousel',
					},
				},
			],
		},
		{
			id: '2',
			widgets: [
				{
					widgetName: 'content-recommendation-widget',
					name: 'HomePageRightTop',
					sequence: 0,
					properties: {
						emsName: 'HomePageRightTop',
					},
				},
				{
					widgetName: 'content-recommendation-widget',
					name: 'HomePageRightBottom',
					sequence: 1,
					properties: {
						emsName: 'HomePageRightBottom',
					},
				},
			],
		},
		{
			id: '3',
			widgets: [
				{
					widgetName: 'content-recommendation-widget',
					name: 'HomePageBrands',
					sequence: 0,
					properties: {
						emsName: 'HomePageBrands',
					},
				},
			],
		},
		{
			id: '4',
			widgets: [
				{
					widgetName: 'content-recommendation-widget',
					name: 'HomePageSafetyMessage',
					sequence: 0,
					properties: {
						emsName: 'HomePageSafetyMessage',
					},
				},
			],
		},
		{
			id: '5',
			widgets: [
				{
					widgetName: 'catalog-entry-recommendation-widget',
					name: 'HomePageFeaturedProducts',
					sequence: 0,
					properties: {
						emsName: 'HomePageFeaturedProducts',
					},
				},
				{
					widgetName: 'content-recommendation-widget',
					name: 'HomePageStoriesAndArticles',
					sequence: 1,
					properties: {
						emsName: 'HomePageStoriesAndArticles',
					},
				},
				{
					widgetName: 'content-recommendation-widget',
					name: 'HomePageBottom',
					sequence: 2,
					properties: {
						emsName: 'HomePageBottom',
					},
				},
			],
		},
	],
};
export default Layout;
